@import 'src/styles/variables';
@import 'src/styles/mixins';

.hide-mobile {
  @include useBreakpoint($breakpoint-sm) {
    display: none;
  }
}

.hide-md {
  @include useBreakpoint($breakpoint-md) {
    display: none;
  }
}

.show-mobile {
  @include useMinBreakpoint($breakpoint-sm) {
    display: none;
  }
}
