@import 'variables';
@import 'mixins';
@import 'partials/grid';
@import 'partials/icons';
@import 'partials/buttons';
@import 'partials/link';
@import 'partials/forms';
@import 'partials/normalize';
//@import 'partials/fonts';


#root {
	display: flex;
	height: 100vh;
	width: 100%;
	//background-color: #02062911;

}


.home-icon{
	position: relative;
	top:4px;
	color: $primary-color;
	a &:hover {
		color: #0815ff;
	}
}
@media (min-width: 768px) {

	.main{
		position: fixed;
		//height: calc(100vh - 50px);
		top:50px;
		right: 0;
		left: 200px;
		bottom: 80px;
		display: grid;
		//background-color: #02062911;
		h1 {
			text-align: center;
			padding-top: 10px;
			height:50px;
		}
	}
}

@media (max-width: 768px) {
	.main{
		position: fixed;
		height: 100%;
		top:100px;
		right: 0;
		left: 0;
		bottom: 80px;
		background-color: white;
		display: grid;
		width: 100%;
		//height: calc(100vh - 200px);
		//width: calc(100% - 200px);
		h1 {
			color: $primary-color;
			text-align: center;
			padding-top: 6px;
			height:50px;
		}
	}
}

@media (min-width: 768px) {
	.top_nav {
		position: fixed; /* Set the navbar to fixed position */
		height: 50px;
		top: 0;
		left: 200px;
		right: 0;
		background-color: white;
		overflow: hidden;
		//width: 100%; /* Full width */
		border-bottom: 1px solid #02062911;
	}

	.toolbar {
		position: fixed;
		width: 100%;
		display: inline-flex;
		vertical-align: text-bottom;


		.icon-refresh-page {
			position: fixed;
			right: 20px;
			top: 8px;
			border-radius: 100%;
			height: 35px;
			width: 35px;
			padding-top: 5px;
			//margin-right: 50px;
			//margin-left: auto;
			background: transparent;
			border: none;

			&:hover, &:active {
				//color: #0815ff;
				//scale: 105%;
				transition-duration: 0.15s;
				background-color: #eee5ff;

			}
		}

	}


	.bottom_nav {
		position: fixed; /* Set the navbar to fixed position */
		left: 200px;
		bottom: 0;
		right: 0;
		height: 70px;
		//top: calc(100vh - 150px);
		//width: calc(100% - 200px);
		background-color: white;
		border-top: 1px solid #02062911;
	}
}



@media (max-width: 768px) {
	.top_nav {
		position: fixed; /* Set the navbar to fixed position */
		height: 50px;
		top: 50px;
		left: 0;
		background-color: white;
		overflow: hidden;
		width: 100%; /* Full width */
		border-bottom: 1px solid #02062911;

	}


	.bottom_nav {
		position: fixed; /* Set the navbar to fixed position */
		bottom: 0;
		left: 0;
		right: 0;
		height: 70px;
		//top: calc(100vh - 50px);
		//width: 100%;
		background-color: white;
		border-top: 1px solid #02062911;

	}
}




.relative {
	position: relative;
}

.flex {
	display: flex;
}

.flex-end {
	display: flex;
	justify-content: flex-end;
}

.flex-column {
	display: flex;
	flex-direction: column;
}

.flex-grow {
	display: flex;
	flex-grow: 1;

	&-centered {
		@extend .flex-grow;
		justify-content: center;
		align-items: center;
	}
}

.align-center {
	display: flex;
	align-items: center;
}

.align-start {
	display: flex;
	align-items: flex-start;
}

.space-between {
	display: flex;
	justify-content: space-between;
}

.flex-width {
	display: flex;
	width: 100%;
}

.flex-wrap {
	flex-wrap: wrap;
}

.row {
	display: flex;
	justify-content: space-between;
	margin-top: calc(#{$content-padding} - #{$input-padding-bottom});
}

.col-2 {
	width: calc(50% - #{$input-gap});
}

.app-wrapper {
	display: flex;
	height: 100vh;
	width: 100%;

	&--column {
		flex-direction: column;
	}

	&--survey {
		font-family: Roboto, sans-serif;
	}
}

.public-wrapper,
.private-wrapper {
	display: flex;
	flex-grow: 1;
	overflow: hidden;
}

.content {
	&-wrapper {
		position: relative;
		flex-grow: 1;
		background: $bg-content;
		padding: 26px $content-padding 20px;
		overflow: auto;
	}

	&-header {
		display: flex;
		justify-content: space-between;

		&__filter {
			width: 300px;
		}
	}
}



h2 {
	font-weight: 300;
	font-size: 36px;
	line-height: 44px;
	margin: 0;
}

h3 {
	font-weight: 300;
	font-size: 18px;
	margin: 0;
}

.title {
	margin-bottom: 22px;
	font-weight: 400;
	font-size: 20px;
	line-height: 24px;
	display: flex;
	align-items: center;
}

.subtitle {
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
}

.main-content {
	overflow-x: hidden;
	flex-grow: 1;
}

//.section {
//	& + & {
//		margin-top: $section-gap;
//
//		&--button {
//			margin-top: 39px;
//		}
//	}
//
//	&__title {
//		font-size: 18px;
//		line-height: 1.33;
//		font-weight: 300;
//		display: flex;
//		align-items: flex-end;
//	}
//
//	&__block {
//		margin-top: $gap;
//
//		&--table {
//			background: #fff;
//			border-radius: $border-radius;
//		}
//
//		&--content {
//			background: #fff;
//			border-radius: $border-radius;
//			padding: 24px;
//
//			&--secondary {
//				color: $font-color-secondary;
//			}
//		}
//	}
//}

.divider {
	background: $border-color;
	border: none;
	margin: 40px 0;
	height: 1px;
}

.details {
	width: 354px;
	min-width: 354px;
	overflow: auto;

	&-block {
		padding: 34px;

		&.details-block--with-list {
			padding-bottom: 0;
		}

		&:first-child {
			padding-top: 21px;
		}

		& + & {
			border-top: 1px solid $border-color;
		}

		&__controls {
			display: flex;
			justify-content: flex-end;
		}

		&__control {
			@include size(24px);
			padding: 0;
			transition: 0.2s;

			&:hover {
				background: $bg-icon-hover;
				border-radius: 5px;
			}

			& + & {
				margin-left: 12px;
			}

			&-btn {
				@include size(24px);
			}
		}

		&__logo {
			height: 55px;
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center;
			width: 100%;
		}

		&__link {
			margin: $gap auto 0;
			display: flex;
			width: fit-content;
			min-width: 55px;
		}

		&__text {
			text-align: center;
			font-size: 14px;
			color: $font-color-secondary;
			margin-top: 8px;

			a {
				text-decoration: underline;
			}
		}
	}

	&-list {
		&__item {
			& + & {
				margin-top: 30px;
			}
		}

		&__title {
			font-size: 14px;
			color: $font-color-secondary;
		}

		&__value {
			margin-top: 4px;
		}
	}
}

.bottom-btn {
	margin: 45px 0 40px;
}

.bottom-right-btn {
	margin-left: auto;
	margin-top: 40px;
}

.pointer {
	cursor: pointer;
}

.width-auto {
	width: auto;
}

.padding-bottom-med {
	padding-bottom: 23px;
}

.last-ordered {
	order: 10;
}

.survey-tabs {
	flex-grow: 0;
}

.hidden {
	visibility: hidden;
}

.display-none {
	display: none !important;
}

.delete-modal {
	h2 {
		font-size: 26px;
		line-height: 36px;
	}
}

.layout-control {
	&_with-margin {
		margin-left: 40px;
	}

	&__btn {
		color: $font-color-disabled;
		padding: 7px;
		border-radius: $border-radius;
		line-height: 14px;
		transition: 0.3s;

		& + & {
			margin-left: 17px;
		}

		&--active {
			color: $bg-icon-chevron;
			background: $bg-icon-hover;
		}

		&-icon {
			@include size(14px);
		}
	}
}

@import 'partials/mediaQueries';
