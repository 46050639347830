@import 'variables';

@media (min-width: 768px) { //and (min-height:1024px)
  .page_path {
    position: absolute;
    margin: 12px 0 0 18px;
  }
  .container {
    margin-right: auto;
    margin-left: auto;
    //margin: 5%;
    display: grid;
    grid-gap: 32px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    ////grid-template-columns: 1fr 1fr 1fr;
    //gap: 2%;

    .bx_upload {
      //scroll-snap-align: start;
      //align-content: start;
      gap: 1.25rem;
      margin: 10px;
      display: flex;
      position: relative;


      align-items: flex-start;
      border-radius: 6px;
      cursor: pointer;
      display: flex;
      min-width: 0;
      transition: opacity .15s;

    }

    .bx_service {
      scroll-snap-align: start;
      align-content: start;
      gap: 1.25rem;
      margin: 10px;
      display: inline-flex;
      position: relative;
    }

    .bx_progress {
      scroll-snap-align: start;
      align-content: start;
      gap: 1.25rem;
      margin: 10px;
      display: inline-flex;
      position: relative;
    }
  }
  .bx {
    height: 100%;
    //width: 90%;
    width: 300px;
    border: 1px solid $primary-color; //$primary-color;
    padding-bottom: 50px;
    text-align: center;
    padding-top: 20px;
    border-radius: 5px;
    font-family: 'D-DIN', sans-serif;

  }
  .blurb {
    height: 100px;
    font-size: 14pt;
    text-align: left;
    padding: 0 0 20px 20px;

    flex: 1 1 auto;
    min-width: 0;
    padding-left: 16px;
  }

}

@media (max-width: 768px) { //and (max-height:1024px)
  .page_path {
    position: absolute;
    margin: 12px 0 0 12px;
  }
  .container {
    margin-right: auto;
    margin-left: auto;
    //margin: 5%;
    //display: grid;
    ////grid-template-columns: 1fr 1fr 1fr;
    //gap: 2%;

    .bx_upload {
      //scroll-snap-align: start;
      //align-content: start;
      gap: 1.25rem;
      margin: 10px;
      display: inline-grid;
      position: relative;
      background-color: blue;

    }

    .bx_service {
      scroll-snap-align: start;
      align-content: start;
      gap: 1.25rem;
      margin: 10px;
      display: inline-flex;
      position: relative;
    }

    .bx_progress {
      scroll-snap-align: start;
      align-content: start;
      gap: 1.25rem;
      margin: 10px;
      display: inline-flex;
      position: relative;
    }
  }
  .bx {
    height: 100%;
    //width: 90%;
    width: 300px;

    border: 1px solid $primary-color;
    padding-bottom: 50px;
    text-align: center;
    padding-top: 20px;
    border-radius: 5px;
    font-family: 'D-DIN', sans-serif;
    display: inline-block;
  }
  .blurb {
    height: 100px;
    font-size: 14pt;
    text-align: left;
    padding: 0 0 20px 20px;
  }
}