@import '../variables';
@import '../mixins';

.icon {
  display:      inline-block;
  font-size:    1em;
  width:        1em;
  stroke-width: 0;
  stroke:       currentColor;
  fill:         currentColor;

  &--pointer {
    cursor: pointer;
  }
}

.stroke {
  stroke: $bg-icon;
}

.no-data-icon {
  @include size(74px);
  margin-bottom: 30px;
}

.clock-icon {
  @include size(24px);
}
