@import url('https://fonts.cdnfonts.com/css/d-din');

@media (min-width: 768px) { //and (max-height:1024px)
  .App {
    //background-color: #ff0;
    margin-left: 200px;
    top: 0;
  }
}

@media (max-width: 768px) { //and (max-height:1024px)

  .App {
    margin-left: 0;
    top: 50px;
    width: 100%;
    min-width: 375px;
  }
}


[data-amplify-authenticator] {
  margin-left: auto;
  margin-right: auto;

  --amplify-components-authenticator-modal-background-color: white;

  --amplify-components-authenticator-router-border-width: 0;
  --amplify-components-authenticator-router-box-shadow: 0 0 0 0;

  --amplify-components-button-primary-background-color: #020629;
  --amplify-components-button-primary-hover-background-color: #0815ff;

  --amplify-components-button-link-color: #020629;
  --amplify-components-button-link-hover-color: #0815ff;

  --amplify-components-tabs-item-color: #020629;
  --amplify-components-tabs-item-hover-color: #0815ff;
  --amplify-components-tabs-item-active-color: #020629;
  --amplify-components-tabs-item-active-border: 2px solid;
  --amplify-components-tabs-item-active-border-color: #020629;
}

.user_icon {
  float: right;
  //padding: 2%;
  margin-left: auto;
  background-color: transparent;
}

.logo-center{
  margin-right: 35%;
  margin-left: 35%;
  padding-bottom: 3rem;
  height:30%;
  width:30%;
  opacity:100%;
}


