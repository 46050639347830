@import 'variables';
@media (max-width: 768px) { //and (max-height:1024px)
  .timestamp{
    display: flex !important;
    align-items: middle !important;
    font-weight: 200;
    color: #02062999;
    padding: 0;
    font-style: oblique;
  }

  .toolbar {
    position: fixed;
    width: 100%;
    display: flex;
    vertical-align: text-bottom;

    .icon-refresh-page {
      position: fixed;
      right: 20px;
      top: 58px;
      border-radius: 100%;
      height: 35px;
      width: 35px;
      padding-top: 5px;
      background: transparent;
      border: none;

      &:hover, &:active {
        //color: #0815ff;
        //scale: 105%;
        transition-duration: 0.15s;
        background-color: #eee5ff;

      }
    }
  }

  .table-div-mobile {
    min-width: 300px;
    max-width: 100%;
    position: fixed;
    //display: block;
    top: 150px;
    //padding: 10px;
    left: 0;
    right: 0;
    bottom: 70px;
    background-color: #02062911;
    overflow-y: scroll;
    border: 1px solid #02062911;

    .download_table {
      //display: table;
      width: 100%;
      //min-width: 700px;

      margin-left: auto;
      margin-right: auto;

      .section {
        color: $primary-color;
        display: grid;
        background-color: white;
        //height: 50px;
        padding: 10px;
        max-width: 100%;
        //min-width: 400px;
        margin-left: auto;
        margin-right: auto;
        //margin-bottom: 10px;
        //border-radius: 4px;
        border-bottom:1px solid #02062911;
        //border-left: 3px solid red;
        //box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
        &:hover {
          //border-color: #0815ff;
          //scale: 105%;
          transition-duration: 0.15s;
          scale: 1.005;
        }
        .file-download-header {
          display: inline-flex;
          //width: 100%;
          .file-download-name {
            width: calc(100% - 130px);
            left: 0;
            font-size: 18px;
            font-weight: 600;
            padding: 5px;
            justify-content: space-between;
            color: #020629ee;
          }

          .file-download-actions {
            right:0;
            //margin-top: 5px;
            display: inline-flex;
            justify-content: space-evenly;
            //width: 25%;
            width: 130px;
            color: $primary-color;
           margin: 0;
            //right: 0;
            //margin-left: auto;

            .icon-download {
              width: 30%;
              margin: 0;
              &:hover, &:active {
                color: #0815ff;
              }
            }

            .icon-download-disabled {
              width: 30%;
              margin: 0;
              color: #02062955;
            }

            .icon-trash-disabled {
              width: 30%;
              margin: 0;
              color: #02062955;
            }

            .icon-trash {
              width: 30%;
              margin: 0;

              &:hover, &:active {
                color: #ef4146;
              }
            }
          }
        }

        .file-download-details {
          color: #02062999;
          //max-width: 50%;
          display: inline-flex;
          padding: 0 0 0 5px;
          justify-content: space-between;
          font-size: 13px;

          .file-download-date {
            display: inline-flex;
            left: 0;
            width: 70%;


            p {
              left: 0;
              text-align: left;
              //margin: 0;
              //display: inline-flex;
            }
          }

          .file-download-size {
            display: inline-flex;
            max-width: 15%;
            p {
              //margin: 0;
              //display: flex;

            }
          }

          .file-credits {
            display: inline-flex;
            max-width: 15%;
            p {
              //margin: 0;
              //display: flex;

            }
          }

          .file-download-status {
            display: inline;
            right: 0;
            width: 25%;
            text-align: center;
            p {
              text-align: left;
            }
          }


        }
      }
    }
    .hidden {
      display: none;
      //visibility: hidden-desktop;
    }
  }
}

@media (min-width: 768px) {
.timestamp{
  display: flex !important;
  align-items: middle !important;
  font-size: smaller;
  font-weight: 200;
  color: #02062999;
  padding: 0;
  font-style: oblique;
}
  .table-div-desktop {
    display: inline;
    position:absolute;
    top: 60px;
    left: 5%;
    right: 5%;    //margin-right: auto;
    //margin-left: auto;
    bottom:200px;
    padding-bottom: 0;


    .section-header {
      display: inline-flex;
      width: 100%;
      min-height: 60px;
      //min-width: 100px;
      padding: 0 10px 0 10px;
      margin-right: auto;
      margin-left: auto;
      font-weight: 400;
      font-size: 18px;
      background: $primary-color;
      border-radius: 4px 4px 0 0;
      color: white;

      .file-name-header {
        display: inline-flex;
        padding: 0 10px 0 0px;
        min-width: 100px;
        width: 40%;
        min-width: 150px;
        font-weight: bold;
        overflow-wrap: break-word;

        p {
          width: 100%;
          text-align: left;
        }
      }

      .file-date-header {
        display: inline-flex;
        padding: 0 10px 0 10px;
        width: 20%;
        min-width: 100px;
        font-weight: bold;
        overflow-wrap: break-word;
        p {
          width: 100%;
          text-align: center;
        }
      }

      .file-credits-header {
        display: inline-flex;
        padding: 0 10px 0 10px;
        width: 10%;
        font-weight: bold;
        align: center;
        overflow-wrap: unset;
        p {
          text-align: center;
          width: 100%;

        }
      }

      .file-download-status-header {
        display: inline-flex;
        padding: 0 10px 0 10px;
        width: 15%;
        min-width: 75px;
        font-weight: bold;
        text-align: center;
        overflow-wrap: unset;


        p {
          width: 100%;
          text-align: center;
        }
      }

      .file-download-size-header {
        display: inline-flex;
        padding: 0 10px 0 10px;
        width: 10%;
        font-weight: bold;
        align: center;
        min-width: 75px;
        overflow-wrap: break-word;
        p {
          text-align: center;
          width: 100%;
        }
      }

      .file-download-action-header {
        display: inline-flex;
        padding: 0 10px 0 10px;
        width: 15%;
        min-width: 75px;
        font-weight: bold;
        align: center;
        overflow-wrap: unset;

        p {
          width: 100%;
          text-align: center;
        }
      }
    }

    .download_table {
      height: 100%;
      margin-right: auto;
      margin-left: auto;
      overflow-y: scroll;
      //max-height: calc(100vh - 300px);
      width: 100%;
      background: #02062911;
      border-radius: 0 0 4px 4px;
      border: 1px solid #02062911;
      padding: 1px 10px 1px 10px;


      .section {
        display: inline-flex;
        width: 100%;
        margin-top: 5px;
        min-height: 40px;
        margin-right: auto;
        margin-left: auto;
        border-radius: 4px;
        background: #ffffff;

        &:hover {
          scale: 1.005;
          transition-duration: 0.15s;
          box-shadow: rgba(0, 0, 0, 0.12) 0 3px 8px;

        }

        .file-download-name {
          display: inline-flex;
          font-weight: bold;
          min-width: 150px;
          width: 40%;
          //overflow-wrap: anywhere;
          padding: 0 10px 0 10px;
          margin: auto;

          p {
            width: 100%;
            left: 0;
            right:0;
          }
        }

        .file-download-date {
          width: 20%;
          min-width: 100px;
          display: inline-flex;
          overflow-wrap: anywhere;
          padding: 0 10px 0 10px;
          margin: auto;

          p {
            text-align: center;
            width: 100%;
          }
        }

        .file-credits {
          width: 10%;
          min-width: 75px;
          align: center;
          display: inline-flex;
          overflow-wrap: unset;
          padding: 0 10px 0 10px;
          p {
            text-align: center;
            width: 100%;

          }
        }

        .file-download-status {
          width: 15%;
          min-width: 75px;
          display: inline-flex;
          margin: auto;

          p {
            text-align: center;
            width: 100%;
          }
        }

        .file-download-size {
          display: inline-flex;
          width: 10%;
          min-width: 75px;
          overflow-wrap: unset;
          margin: auto;
          p {
            text-align: center;
            width: 100%;
          }
        }

        .file-download-actions {
          display: inline-flex;
          width: 15%;
          min-width: 75px;
          font-size: small;
          overflow-wrap: unset;
          //padding: 0 10px 0 10px;
          vertical-align: middle;
          margin: auto;



          .icon-download {
            //display: inline-grid;
            margin-left: auto;
            margin-right: 1%;

            &:hover, &:active {
              color: #0815ff;
            }
          }


          .icon-download-disabled {
            color: #02062955;
            margin-left: auto;
            margin-right: 1%;
          }

          .icon-trash-disabled {
            color: #02062955;
            margin-left: 1%;
            margin-right: auto;
          }

          .icon-trash {
            //display: flex;
            margin-left: 1%;
            margin-right: auto;

            &:hover, &:active {
              color: #ef4146;
            }
          }


          .icon-preview {
            margin-left: 1%;
            margin-right: auto;

            &:hover, &:active {
              color: forestgreen;;
            }
          }

          .icon-replay {
            display: flex;
            margin-left: auto;
            margin-right: auto;

            &:hover, &:active {
              color: #47b881;;
            }
          }
        }
      }
    }

    .hidden-desktop{
      //display: none;
      visibility: hidden;
    }

  }
}

//.bottom-nav {
//  bottom: 0;
//  left: 200px;
//  right: 0;
//  z-index: 999;
//  position: fixed;
//  vertical-align: middle;
//  height: 100px;
//}

.card {

}

//CSS from other site


.alert-root {
  background: var(--gray-0);
  border-radius: 8px;
  color: var(--gray-0);
  display: inline-flex;
  flex-direction: row;
  padding: 8px 16px 9px
}

.alert-full {
  display: flex
}

.alert-info {
  background-color: var(--gray-800)
}

.alert-neutral {
  background-color: var(--gray-50);
  border: 1px solid var(--gray-100);
  color: var(--gray-900)
}

.alert-neutral .alert-icon {
  color: var(--gray-500)
}

.alert-success {
  background-color: #47b881;
  border: 1px solid #47b881
}

.alert-warning {
  background-color: #d9822b
}

.alert-danger {
  background-color: var(--red-500)
}

.icon-item {
  align-items: flex-start;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  min-width: 100px;
  max-width:320px;
  transition: opacity .15s
}

.icon-item:hover {
  //opacity: .9;
  background: #eee5ff
}

.icon-item-icon {
  border-radius: 4px;
  color: #fff;
  flex: 0 0 auto;
  font-size: 24px;
  line-height: 0;
  padding: 15px;
}

.icon-item-icon:hover {
  background-color: #0815ff;
  scale: 105%;
  transition: .3s
}

.icon-item-icon svg {
  transition: transform .15s;
  transition: transform .15s, -webkit-transform .15s
}

.icon-item:hover .icon-item-icon svg {
  -webkit-transform: scale(1.15);
  transform: scale(1.15);
}

.icon-item-right {
  flex: 1 1 auto;
  min-width: 100px;
  max-width: 300px;
  padding-left: 16px;
}

.icon-item-title {
  color: $primary-color;
  display: inline-block;
  margin-bottom: 2px;
  margin-top: 3px;
  transition: color .15s
}

.icon-item-tag {
  color: #0815ff;


}


.icon-item-tag {
  background-color: var(--gray-100);
  border-radius: 8px;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  margin-left: 8px;
  padding: 2px 6px;
  -webkit-transform: translateY(-2px);
  transform: translateY(-2px)
}

.icon-item-tag.icon-item-tag-green {
  background-color: var(--green-100);
  color: var(--green-700)
}

.icon-item-desc {
  color: $primary-color; //var(--gray-600);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  text-wrap: nowrap;
}

.vxf-page {
  background-color: var(--bg-color);
  box-sizing: border-box;
  margin: 0 auto
}

.vxf-page.page-body {
  padding-top: 42px
}

.vxf-page h1 {
  font-size: 32px;
  margin-bottom: 40px;
  margin-top: 0
}

.vxf-page h1 > div {
  margin-top: -14px
}

@media (min-width: 450px) {
  .vxf-page h1 .mobile-break {
    display: none
  }
}

.vxf-page h2 {
  margin-bottom: 24px
}

.vxf-page .section-text {
  color: var(--gray-800);
  font-size: 16px;
  line-height: 24px
}

.vxf-page a {
  text-decoration: none
}

.vxf-page .vxf-shortcuts {
  grid-gap: 32px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  margin: 24px 0 64px
}

@media (max-width: 700px) {
  .vxf-page .vxf-shortcuts {
    grid-template-columns:1fr
  }
}

.vxf-page .vxf-card {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 192px;
  justify-content: flex-end;
  overflow: hidden;
  padding: 20px 24px;
  position: relative
}

@media (max-width: 800px) {
  .vxf-page .vxf-card {
    height: 164px
  }
}

.vxf-page .vxf-card:hover .vxf-card-bg {
  opacity: .9
}

.vxf-page .vxf-card-bg {
  bottom: 0;
  height: auto;
  left: 0;
  min-height: 100%;
  opacity: 1;
  position: absolute;
  transition: opacity .2s ease-in-out;
  width: 100%
}

.vxf-page .vxf-card-title {
  color: #fff;
  position: relative;
  transition: color .2s ease-in-out;
  z-index: 2
}

.vxf-page .vxf-card-desc {
  color: #fff;
  margin-top: 2px;
  position: relative;
  z-index: 2
}

.vxf-page .vxf-going-live-warning {
  margin-bottom: 32px
}

.vxf-page .vxf-build-grid {
  grid-gap: 32px;
  display: grid;
  grid-template-columns:1fr 1fr
}

@media (max-width: 700px) {
  .vxf-page .vxf-build-grid {
    grid-template-columns:1fr
  }
}

.vxf-page .teams-support {
  color: var(--gray-800);
  margin-bottom: 10px
}

.vxf-page .teams-support a, .vxf-page .teams-support a:visited {
  font-weight: 700
}

.vxf-page .vxf-section + .vxf-section {
  margin-top: 48px
}

@media (min-width: 1372px) {
  .docs-body .vxf-page {
    margin-left: calc(50vw - 686px)
  }
}

.docs-body .vxf-footer {
  display: block
}

.vxf-footer-grid {
  grid-gap: 48px;
  display: grid;
  grid-template-columns:1fr 1fr 1fr
}

@media (max-width: 800px) {
  .vxf-footer-grid {
    grid-template-columns: 1fr 1fr;
    justify-items: center
  }
}

@media (max-width: 600px) {
  .vxf-footer-grid {
    grid-template-columns: 1fr;
    justify-items: center
  }
}

.vxf-footer-item {
  max-width: 100%;
  text-align: center
}

.vxf-footer-item a {
  display: block
}

.vxf-footer-desc {
  color: var(--gray-600)
}

.vxf-footer + .docs-footer {
  display: none
}


:root {
  //--primary: #10a37f;
  //--primary-50: #ebfaeb;
  //--primary-100: #d2f4d3;
  //--primary-200: #b9eebc;
  //--primary-300: #93e69c;
  //--primary-400: #68de7a;
  //--primary-500: #19c37d;
  //--primary-600: #10a37f;
  //--primary-700: #1a7f64;
  //--primary-800: #1b5d4a;
  //--primary-900: #183d31;
  //--primary-50a: rgba(16, 163, 127, .2);
  //--primary-100a: rgba(16, 163, 127, .3);
  --secondary: #5436da;
  --secondary-50: #ecebf9;
  --secondary-100: #d2cff2;
  --secondary-200: #b9b4ec;
  --secondary-300: #a198e6;
  --secondary-400: #897ce2;
  --secondary-500: #715fde;
  --secondary-600: #5436da;
  --secondary-700: #482da8;
  --secondary-800: #3b2479;
  --secondary-900: #281852;
  --secondary-100a: rgba(84, 54, 218, .3);
  //--green-50: #ebfaeb;
  //--green-100: #d2f4d3;
  //--green-200: #b9eebc;
  //--green-300: #93e69c;
  //--green-400: #68de7a;
  //--green-500: #19c37d;
  //--green-600: #10a37f;
  //--green-700: #1a7f64;
  //--green-800: #1b5d4a;
  //--green-900: #183d31;
  //--green-100a: rgba(16, 163, 127, .3);
  --red-50: #fdebeb;
  --red-100: #f9cfcf;
  --red-200: #f6b2b3;
  --red-300: #f49394;
  --red-400: #f17173;
  --red-500: #ef4146;
  --red-600: #c23539;
  --red-700: #9d2b2e;
  --red-800: #7b2124;
  --red-900: #59181a;
  --red-100a: rgba(239, 65, 70, .3);
  --gray-0: #fff;
  --gray-50: #f7f7f8;
  --gray-75: #f2f2f5;
  --gray-100: #ececf1;
  --gray-150: #e3e3eb;
  --gray-200: #d9d9e3;
  --gray-250: #d1d1dc;
  --gray-300: #c5c5d2;
  --gray-350: #b8b8cb;
  --gray-400: #acacbe;
  --gray-450: #9d9dae;
  --gray-500: #8e8ea0;
  --gray-550: #7e7e90;
  --gray-600: #6e6e80;
  --gray-650: #5f5f75;
  --gray-700: #565869;
  --gray-750: #46464d;
  --gray-800: #353740;
  --gray-850: #282832;
  --gray-900: #202123;
  --gray-950: #101014;
  --warm-gray-100: #e6f3f3;
  --pink-100: #dd5ce5;
  --medium-wash: #eff7f8;
  --bg-color: var(--gray-0);
  --text-primary: var(--gray-900);
  --text-default: var(--gray-800);
  --text-secondary: var(--gray-600);
  --text-disabled: var(--gray-500);
  --text-error: #ef4146;
  --nav-color: var(--gray-50);
  --divider: var(--gray-100);
  //--nav-bg: var(--green-50);
  //--nav-text: var(--green-700);
  --nav-hover: var(--gray-50);
  //--pill-success-text: var(--green-800);
  //--pill-success-bg: var(--green-100);
  --pill-danger-text: var(--red-700);
  --pill-danger-bg: var(--red-100);
  --pill-warning-bg: #ffe8c4;
  --pill-warning-text: #945e0c;
  --code-snippet-bg: var(--gray-900);
  --code-snippet-header: var(--gray-800);
  --white: var(--gray-50);
  --dark: var(--gray-800);
  --elevated: var(--gray-0);
  --shadow: 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -4px rgba(0, 0, 0, .1);
  //--button-primary: var(--green-600);
  //--button-primary-hover: var(--green-700);
  //--highlighted-text: var(--green-100);
  --disabled-input: var(--gray-50);
  --input-border: var(--gray-100);
  //--input-border-focus: var(--primary-600);
  //--input-focus-ring: 0 0 0 1px #10a37f;
  --icon-warning-color: #f4ac36;
  --border-radius-sm: 4px;
  --border-radius: 8px;
  --heading-margin-top: 30px;
  --heading-margin-bottom: 16px;
  --header-height: 52px;
  --sidenav-width: 57px;
  --content-width: 760px;
  --content-v-padding: 40px;
  --content-h-padding: 56px;
  --mobile-breakpoint: 600px;
  --font-size-small: 16px;
  //--sans-serif: "Söhne", helvetica, sans-serif;
  //--monospace: "Söhne Mono", sfmono-regular, consolas, liberation mono, menlo, courier, monospace
}

:root body.dark {
  --gray-0: #000;
  --gray-50: #202123;
  --gray-100: #353740;
  --gray-200: #565869;
  --gray-300: #6e6e80;
  --gray-400: #8e8ea0;
  --gray-500: #acacbe;
  --gray-600: #c5c5d2;
  --gray-700: #d9d9e3;
  --gray-800: #ececf1;
  --gray-900: #f7f7f8;
  --gray-1000: #fff;
  --gray-75: #46464d;
  --gray-150: #5f5f75;
  --gray-250: #7e7e90;
  --gray-350: #9d9dae;
  --gray-450: #b8b8cb;
  --gray-550: #9d9dae;
  --gray-650: #b8b8cb;
  --gray-750: #d1d1dc;
  --gray-850: #e3e3eb;
  --gray-950: #f2f2f5;
  //--warm-gray-100: var(--green-900);
  //--highlighted-text: var(--green-800);
  --secondary-50: #482da8;
  --secondary-100: #5436da;
  --secondary-600: #b9b4ec;
  --bg-color: var(--gray-50);
  --divider: var(--gray-0);
  --elevated: #2b2c32;
  --primary-button-text: var(--gray-1000);
  --text-primary: var(--gray-900);
  --text-default: var(--gray-700);
  --text-secondary: var(--gray-600);
  --text-disabled: var(--gray-400);
  --text-error: var(--red-400);
  //--nav-color: #171719;
  //--nav-bg: var(--green-900);
  //--nav-text: var(--green-100);
  //--nav-hover: var(--gray-100);
  //--pill-success-text: var(--green-100);
  //--pill-success-bg: var(--green-800);
  --pill-danger-text: var(--red-100);
  --pill-danger-bg: var(--red-800);
  --pill-warning-text: #ffe8c4;
  --pill-warning-bg: #945e0c;
  //--button-primary: var(--green-700);
  //--button-primary-hover: var(--green-600);
  --disabled-input: var(--gray-100);
  --code-snippet-bg: var(--gray-0);
  --code-snippet-header: var(--gray-50)
}

body, html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  background-color: var(--bg-color);
  //color: #353740;
  //color: var(--text-default);
  //font-family: Söhne, helvetica, sans-serif;
  //font-family: var(--sans-serif);
  font-size: 14px;
  font-size: var(--font-size-small);
  height: 100%;
  margin: 0;
  padding: 0
}

*, :after, :before {
  box-sizing: border-box
}


#root {
  height: 100%;
  overflow: auto
}

//.page-wrapper {
//  background-color: #fff;
//  background-color: var(--bg-color);
//  box-sizing: border-box;
//  color: #353740;
//  color: var(--text-default);
//  display: flex;
//  flex-direction: column;
//  height:100%
//}

//.route-container {
//  height: 100%;
//  position: relative;
//  z-index:1
//}

@media print {
  #root {
    height: auto
  }

  .page-wrapper {
    height: auto;
    padding-top: 0
  }
}

.page-body {
  flex: 1 0 auto;
  font-size: 16px;
  line-height: 24px;
  margin: 0 auto;
  max-width: 100%;
  padding: 40px 56px;
  padding: var(--content-v-padding) var(--content-h-padding);
  width: 872px;
  width: calc(var(--content-width) + var(--content-h-padding) * 2)
}

.page-body.full-width {
  width: 100%
}

.page-body.short-width {
  width: 670px
}

.page-body.medium-width {
  width: 900px
}

.page-body.large-width {
  width: 1040px
}

.page-body.flush {
  padding: 0
}

.container {
  margin: 0 auto;
  max-width: 100%;
  padding: 0 56px;
  padding: 0 var(--content-h-padding);
  width: 872px;
  width: calc(var(--content-width) + var(--content-h-padding) * 2)
}

.container.full-width {
  width: 100%
}


h1, h2, h3, h4, h5, h6 {
  color: #202123;
  color: var(--gray-900);
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: 16px;
  margin-bottom: var(--heading-margin-bottom);
  margin-top: 30px;
  margin-top: var(--heading-margin-top)
}

h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child {
  margin-top: 0
}

h1 {
  font-size: 32px;
  line-height: 40px
}

h2 {
  font-size: 24px;
  line-height: 32px
}

h3 {
  font-size: 20px;
  line-height: 28px
}

h4 {
  font-size: 18px;
  line-height: 24px
}

h5 {
  font-size: 16px
}

h6 {
  font-size: 12px
}

.monospace {
  font-family: Söhne Mono, sfmono-regular, consolas, liberation mono, menlo, courier, monospace;
  font-family: var(--monospace)
}

.text-title {
  font-size: 32px;
  line-height: 40px
}

.heading-large, .text-title {
  color: #202123;
  color: var(--gray-900);
  font-weight: 700
}

.heading-large {
  font-size: 24px;
  line-height: 32px
}

.heading-medium {
  font-size: 20px;
  line-height: 28px
}

.heading-medium, .heading-small {
  color: #202123;
  color: var(--gray-900);
  font-weight: 700
}

.heading-small {
  font-size: 18px;
  line-height: 24px
}

.subheading {
  color: #202123;
  color: var(--gray-900);
  font-size: 12px;
  font-weight: 700;
  letter-spacing: .08em;
  line-height: 16px;
  text-transform: uppercase
}

.text-intro {
  font-size: 18px;
  line-height: 16px
}

.body-large {
  font-size: 16px
}

.body-large, .body-large-mono {
  font-weight: 400;
  line-height: 24px
}

.body-large-mono {
  font-family: Söhne Mono, sfmono-regular, consolas, liberation mono, menlo, courier, monospace;
  font-family: var(--monospace);
  font-size: 15px
}

.body-small, .body-small-secondary {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px
}

.body-small-secondary {
  color: #6e6e80;
  color: var(--text-secondary)
}

.body-small-mono {
  font-family: Söhne Mono, sfmono-regular, consolas, liberation mono, menlo, courier, monospace;
  font-family: var(--monospace);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px
}

.caption {
  color: #6e6e80;
  color: var(--text-secondary);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px
}

.mono, pre, pre > code {
  font-family: Söhne Mono, sfmono-regular, consolas, liberation mono, menlo, courier, monospace;
  font-family: var(--monospace)
}

.mono {
  background-color: #ececec;
  padding: .5em .2em;
  word-break: break-all
}

.bold {
  font-weight: 700
}

.italic {
  font-style: italic
}

.tabular-nums {
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums
}

.nowrap {
  white-space: nowrap
}

.mt-0\.5 {
  margin-top: 2px
}

.pt-0\.5 {
  padding-top: 2px
}

.mb-0\.5 {
  margin-bottom: 2px
}

.pb-0\.5 {
  padding-bottom: 2px
}

.ml-0\.5 {
  margin-left: 2px
}

.pl-0\.5 {
  padding-left: 2px
}

.mr-0\.5 {
  margin-right: 2px
}

.pr-0\.5 {
  padding-right: 2px
}

.mt-1 {
  margin-top: 4px
}

.pt-1 {
  padding-top: 4px
}

.mb-1 {
  margin-bottom: 4px
}

.pb-1 {
  padding-bottom: 4px
}

.ml-1 {
  margin-left: 4px
}

.pl-1 {
  padding-left: 4px
}

.mr-1 {
  margin-right: 4px
}

.pr-1 {
  padding-right: 4px
}

.mt-2 {
  margin-top: 8px
}

.pt-2 {
  padding-top: 8px
}

.mb-2 {
  margin-bottom: 8px
}

.pb-2 {
  padding-bottom: 8px
}

.ml-2 {
  margin-left: 8px
}

.pl-2 {
  padding-left: 8px
}

.mr-2 {
  margin-right: 8px
}

.pr-2 {
  padding-right: 8px
}

.mt-3 {
  margin-top: 12px
}

.pt-3 {
  padding-top: 12px
}

.mb-3 {
  margin-bottom: 12px
}

.pb-3 {
  padding-bottom: 12px
}

.ml-3 {
  margin-left: 12px
}

.pl-3 {
  padding-left: 12px
}

.mr-3 {
  margin-right: 12px
}

.pr-3 {
  padding-right: 12px
}

.mt-4 {
  margin-top: 16px
}

.pt-4 {
  padding-top: 16px
}

.mb-4 {
  margin-bottom: 16px
}

.pb-4 {
  padding-bottom: 16px
}

.ml-4 {
  margin-left: 16px
}

.pl-4 {
  padding-left: 16px
}

.mr-4 {
  margin-right: 16px
}

.pr-4 {
  padding-right: 16px
}

.mt-5 {
  margin-top: 20px
}

.pt-5 {
  padding-top: 20px
}

.mb-5 {
  margin-bottom: 20px
}

.pb-5 {
  padding-bottom: 20px
}

.ml-5 {
  margin-left: 20px
}

.pl-5 {
  padding-left: 20px
}

.mr-5 {
  margin-right: 20px
}

.pr-5 {
  padding-right: 20px
}

.mt-6 {
  margin-top: 24px
}

.pt-6 {
  padding-top: 24px
}

.mb-6 {
  margin-bottom: 24px
}

.pb-6 {
  padding-bottom: 24px
}

.ml-6 {
  margin-left: 24px
}

.pl-6 {
  padding-left: 24px
}

.mr-6 {
  margin-right: 24px
}

.pr-6 {
  padding-right: 24px
}

.mt-8 {
  margin-top: 32px
}

.pt-8 {
  padding-top: 32px
}

.mb-8 {
  margin-bottom: 32px
}

.pb-8 {
  padding-bottom: 32px
}

.ml-8 {
  margin-left: 32px
}

.pl-8 {
  padding-left: 32px
}

.mr-8 {
  margin-right: 32px
}

.pr-8 {
  padding-right: 32px
}

.mt-10 {
  margin-top: 40px
}

.pt-10 {
  padding-top: 40px
}

.mb-10 {
  margin-bottom: 40px
}

.pb-10 {
  padding-bottom: 40px
}

.ml-10 {
  margin-left: 40px
}

.pl-10 {
  padding-left: 40px
}

.mr-10 {
  margin-right: 40px
}

.pr-10 {
  padding-right: 40px
}

.mt-12 {
  margin-top: 48px
}

.pt-12 {
  padding-top: 48px
}

.mb-12 {
  margin-bottom: 48px
}

.pb-12 {
  padding-bottom: 48px
}

.ml-12 {
  margin-left: 48px
}

.pl-12 {
  padding-left: 48px
}

.mr-12 {
  margin-right: 48px
}

.pr-12 {
  padding-right: 48px
}

.mt-16 {
  margin-top: 64px
}

.pt-16 {
  padding-top: 64px
}

.mb-16 {
  margin-bottom: 64px
}

.pb-16 {
  padding-bottom: 64px
}

.ml-16 {
  margin-left: 64px
}

.pl-16 {
  padding-left: 64px
}

.mr-16 {
  margin-right: 64px
}

.pr-16 {
  padding-right: 64px
}

.mt-20 {
  margin-top: 80px
}

.pt-20 {
  padding-top: 80px
}

.mb-20 {
  margin-bottom: 80px
}

.pb-20 {
  padding-bottom: 80px
}

.ml-20 {
  margin-left: 80px
}

.pl-20 {
  padding-left: 80px
}

.mr-20 {
  margin-right: 80px
}

.pr-20 {
  padding-right: 80px
}

p {
  line-height: 1.5em
}

.inline-code, li code, p code {
  background: #f7f7f8;
  background: var(--gray-50);
  border: 1px solid #ececf1;
  border: 1px solid var(--gray-100);
  border-radius: 4px;
  font-family: Söhne Mono, sfmono-regular, consolas, liberation mono, menlo, courier, monospace;
  font-family: var(--monospace);
  font-size: 14px;
  font-weight: 500;
  margin: 0 2px;
  padding: 2px 4px
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none
}

.flex {
  display: flex
}

.items-center {
  align-items: center
}

.items-start {
  align-items: flex-start
}

.items-end {
  align-items: flex-end
}

.items-stretch {
  align-items: stretch
}

.justify-between {
  justify-content: space-between
}

.justify-center {
  justify-content: center
}

.gray-50 {
  color: #f7f7f8;
  color: var(--gray-50)
}

.gray-100 {
  color: #ececf1;
  color: var(--gray-100)
}

.gray-200 {
  color: #d9d9e3;
  color: var(--gray-200)
}

.gray-300 {
  color: #c5c5d2;
  color: var(--gray-300)
}

.gray-400 {
  color: #acacbe;
  color: var(--gray-400)
}

.gray-500 {
  color: #8e8ea0;
  color: var(--gray-500)
}

.gray-600 {
  color: #6e6e80;
  color: var(--gray-600)
}

.gray-700 {
  color: #565869;
  color: var(--gray-700)
}

.gray-800 {
  color: #353740;
  color: var(--gray-800)
}

.gray-900 {
  color: #202123;
  color: var(--gray-900)
}

.color-error {
  color: #ef4146;
  color: var(--text-error)
}

.default-gradient-bg {
  background-color: $primary-color;
  background-size: cover
}

.green-gradient-bg {
  background-color: #19c37d;
  background-size: cover
}

.red-gradient-bg {
  background-color: #ef4146;
  background-size: cover
}

.purple-gradient-bg {
  background-color: #0815ff;
  background-size: cover
}

.pink-gradient-bg {
  background-color: #dd5ce5;
  background-size: cover
}

.orange-gradient-bg {
  background-color: #f4ac36;
  background-size: cover
}

.radio {
  margin: 5px
}

.radio input[type=radio] {
  opacity: 0;
  position: absolute
}

.radio input[type=radio] + .radio-label {
  cursor: pointer
}

.radio input[type=radio] + .radio-label:before {
  background: #fff;
  background: var(--bg-color);
  border: 1px solid #c5c5d2;
  border-radius: 100%;
  box-shadow: inset 0 0 0 4px #fff;
  box-shadow: inset 0 0 0 4px var(--bg-color);
  content: "";
  cursor: pointer;
  display: inline-block;
  height: 1em;
  margin-right: .5em;
  position: relative;
  text-align: center;
  transition: background-color .25s ease, border-color .25s ease;
  vertical-align: top;
  width: 1em
}

.radio input[type=radio]:checked + .radio-label:before {
  background-color: #10a37f
}

.radio input[type=radio]:checked:disabled + .radio-label:before {
  background-color: #93e69c
}

.radio input[type=radio]:focus + .radio-label:before {
  border-color: #8e8ea0;
  outline: none
}

.radio input[type=radio] + .radio-label:empty:before {
  margin-right: 0
}

.spacer {
  flex-grow: 1
}

.markdown-content p {
  line-height: 1.5;
  margin-bottom: 15px;
  margin-top: 0
}

.markdown-content .code-sample {
  margin: 24px 0
}

.markdown-content ol, .markdown-content ul {
  list-style: none;
  padding-left: 0
}

.markdown-content ol {
  counter-reset: ordered-listitem
}

.markdown-content ol ol, .markdown-content ol ul, .markdown-content ul ol, .markdown-content ul ul {
  margin-bottom: 0;
  margin-top: 8px
}

.markdown-content li {
  line-height: 1.4;
  padding-left: 32px;
  position: relative
}

.markdown-content li + li {
  margin-top: 8px
}

.markdown-content ul li:before {
  background: var(--gray-800);
  border-radius: 50%;
  content: "";
  height: 5px;
  left: 15px;
  position: absolute;
  top: 8px;
  width: 5px
}

.markdown-content .md-nonanchor-heading {
  position: relative
}

.markdown-content h1.md-nonanchor-heading {
  font-size: 18px;
  line-height: 1.5
}

.markdown-content h2.md-nonanchor-heading {
  font-size: 16px;
  line-height: 1.5
}

.markdown-content h3.md-nonanchor-heading {
  font-size: 14px;
  line-height: 1.5
}

.markdown-content h4.md-nonanchor-heading, .markdown-content h5.md-nonanchor-heading, .markdown-content h6.md-nonanchor-heading {
  font-size: 12px;
  line-height: 1.5
}

.markdown-content .md-heading-num, .markdown-content ol > li:after {
  background: var(--gray-100);
  border-radius: 20px;
  color: var(--gray-600);
  font-size: 12px;
  font-weight: 400;
  height: 20px;
  line-height: 19px;
  padding-top: 1px;
  text-align: center;
  width: 20px
}

@media (min-width: 768px) {
  .markdown-content .md-heading-num {
    left: -32px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
  }
}

@media (max-width: 767px) {
  .markdown-content .md-heading-num {
    display: inline-block;
    margin-right: 10px;
    vertical-align: top
  }

  .markdown-content h2 .md-heading-num {
    margin-top: 6px
  }
}

.markdown-content ol > li:after {
  content: counter(ordered-listitem);
  counter-increment: ordered-listitem;
  left: 0;
  position: absolute;
  top: 0
}

.markdown-content li > p {
  margin: 0
}

.markdown-content li > p + p {
  margin-top: 15px
}

.markdown-content hr {
  border: none;
  height: 0;
  margin-bottom: 40px;
  width: 100%
}

.markdown-content blockquote {
  background: var(--gray-100);
  color: var(--gray-700);
  margin: 0 0 15px;
  padding: .8em
}

.markdown-content blockquote p:last-child {
  margin: 0
}

.markdown-content.markdown-prompt-blockquote blockquote {
  background-color: var(--gray-50);
  border-radius: 4px;
  color: var(--gray-800);
  font-family: var(--monospace);
  font-size: 15px;
  line-height: 24px;
  margin: 0 0 15px;
  padding: 12px 16px;
  white-space: pre-wrap
}

.markdown-content.markdown-prompt-blockquote blockquote a, .markdown-content.markdown-prompt-blockquote blockquote a:visited {
  color: var(--gray-800)
}

.markdown-content.markdown-prompt-blockquote blockquote strong {
  background-color: var(--pill-success-bg);
  font-weight: 400
}

.markdown-content.markdown-prompt-blockquote blockquote p {
  margin: 0
}

.markdown-content li a, .markdown-content p a {
  text-decoration: none
}

.markdown-content table:not(.ignore-md-styles) {
  border-collapse: collapse;
  margin: 20px 0 15px
}

.markdown-content table:not(.ignore-md-styles) td:first-child, .markdown-content table:not(.ignore-md-styles) th:first-child {
  padding-left: 0
}

.markdown-content table:not(.ignore-md-styles) td:last-child, .markdown-content table:not(.ignore-md-styles) th:last-child {
  padding-right: 0
}

.markdown-content table:not(.ignore-md-styles) th {
  border: none;
  color: var(--gray-900);
  font-size: 12px;
  font-weight: 700;
  letter-spacing: .1em;
  line-height: 16px;
  padding: 0 8px 3px;
  text-align: left;
  text-transform: uppercase;
  vertical-align: bottom
}

.markdown-content table:not(.ignore-md-styles) td {
  border: none;
  border-top: 1px solid var(--gray-100);
  padding: 8px;
  vertical-align: top
}

.markdown-content img {
  max-width: 100%
}

.markdown-content picture img {
  height: auto
}

.markdown-page.models td a {
  white-space: nowrap
}


