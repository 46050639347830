@import '../variables';
.custom_button {
  min-width: 100px;
  max-width: 100%;
  padding: 14px 18px;
  border-radius: 4px;
  background: $button-fill-ordinary;
  color: $button-ordinary-color;
  transition: 0.3s;
  border: 0;
  display: block;
  font-size: 14px;
  line-height: 1em;
  text-transform: uppercase;
  margin: 5px;

  &-lg {
    padding: 14px 20px;
  }

  &-right {
    margin-left: auto;
  }

  &-left {
    margin-right: auto;
  }

  &-center {
    margin-right: auto;
    margin-left: auto;
  }

  &:hover,
  &:focus {
    background: $primary-color;
    scale: 105%;
    transition-duration: 0.3s;
    font-weight: normal;
  }

  &:disabled {
    background: #b5bccb;
  }

  &-secondary {
    @extend .custom_button;
    color: $button-fill-ordinary;
    border: 1px solid $button-fill-ordinary;
    background: #ffffff;

    &:hover,
    &:focus {
      background: #ffffff;
      scale: 105%;
      transition-duration: 0.3s;
    }

    &:disabled {
      color: #b5bccb;
      background: #ffffff;
      border: 1px solid #b5bccb;
    }
  }

}

.bx_bttn,  {
  padding: 14px 18px;
  border-radius: 4px;
  background: $button-fill-ordinary;
  color: #ffffff;
  transition: 0.3s;
  border: 0;
  display: block;
  font-size: 14px;
  line-height: 1em;
  text-transform: uppercase;
  margin: 1%;

  &-lg {
    padding: 14px 20px;
  }

  &-right {
    margin-left: auto;
  }

  &-left {
    margin-right: auto;
  }

  &-center {
    margin-right: auto;
    margin-left: auto;
    padding: 14px 18px;
    border-radius: 4px;
    background: $button-fill-ordinary;
    color: #ffffff;
    transition: 0.3s;
    border: 0;
    display: block;
    font-size: 14px;
    line-height: 1em;
    text-transform: uppercase;
    margin-top: 1%;
    &:hover,
    &:focus {
      background: $primary-color;
      scale: 105%;
      transition-duration: 0.3s;
    }

    &:disabled {
      background: #b5bccb;
    }
  }

  &:hover,
  &:focus {
    background: $primary-color;
    scale: 105%;
    transition-duration: 0.3s;
    font-weight: normal;
  }

  &:disabled {
    background: #b5bccb;
  }

  &-secondary {
    @extend .custom_button;
    color: $button-fill-ordinary;
    border: 1px solid $button-fill-ordinary;
    background: #ffffff;

    &:hover,
    &:focus {
      background: #ffffff;
      scale: 105%;
      transition-duration: 0.3s;
    }

    &:disabled {
      color: #f5f5f5;
      background: #ffffff;
      border: 1px solid #f5f5f5;
    }
  }

  &-cancel {
    @extend .custom_button;
    color: $button-fill-ordinary;
    //border: none;
    background: transparent;
    text-underline: $primary-color;

    &:hover,
    &:focus {
      color: $primary-color;
      scale: 105%;
      transition-duration: 0.3s;
    }

    &:disabled {
      color: #f5f5f5;
    }
  }

}

.button-pair {
  display: inline-flex;
  padding:10px 50px 10px 50px;
  justify-content: space-between;
  width: 100%;
}

.button-pair-dialog {
  display: inline-flex;
  margin: 0 5px 0 5px;
  justify-content: space-between;
  width: 100%;
}

a{
  text-decoration: none;
}

.top-up-button {
  width: 100%;
  //margin-top: auto;
  //margin-bottom: 30px;
  padding: 14px 18px;
  border-radius: 4px;
  background: $button-fill-ordinary;
  color: $button-ordinary-color;
  border: 0;
  display: block;
  //position: absolute;
  font-size: 14px;
  line-height: 1em;
  text-transform: uppercase;
  //text-align: center;
  bottom: 0;
  &:hover {
    scale: 101%;
    transition-duration: 0.3s;
  }
  &:disabled {
    background: #020629;
  }

}

