@import 'variables';

$cont-width: 100%;
$width_table1: 22.5%;
$width_table2: 20%;
$width_table3: 52.5%;
$width_table4: 5%;

.upload-button {
  display: inline-flex !important;
  vertical-align: middle;
 }

//p.invalid-date {
//  color: #ef4146;
//  padding-left: 10px;
//}
.credit-alert-danger{
  font-size: small;
  margin-left: auto;
  right: 12px;
  top: 16px;
  border-radius: 25px;
  padding: 3px 10px 3px 10px;
  background-color: #ef4146;
  color: white;
  font-weight: bold;
  margin-top: 0;
  position: absolute;
 &:hover {
    color: white;
   scale: 105%;
    transition-duration: 0.3s;
  }
}

.credit-alert-warning{
  font-size: small;
  margin-left: auto;
  right: 12px;
  top: 16px;
  border-radius: 25px;
  padding: 3px 10px 3px 10px;
  background-color: #d9822b;
  color: white;
  font-weight: bold;
  margin-top: 0;
  position: absolute;
  &:hover{
    color: white;
    scale: 105%;
    transition-duration: 0.3s;
  }
}

.credit-alert-loading {
  display: inline-flex;
  justify-content: space-around;
  font-size: small;
  margin-left: auto;
  right: 12px;
  top: 16px;
  border-radius: 25px;
  padding: 3px 10px 3px 10px;
  background-color: var(--gray-100);
  color: var(--gray-400);
  font-weight: bold;
  margin-top: 0;
  position: absolute;
}

.credit-spinner{
  margin: 2px -5px 2px 2px;
}


.credit-alert-success{
  font-size: small;
  margin-left: auto;
  right: 12px;
  top: 16px;
  border-radius: 25px;
  padding: 3px 10px 3px 10px;
  background-color: #47b881;
  color: white;
  font-weight: bold;
  margin-top: 0;
  position: absolute;
  &:hover{
    color: white;
    scale: 105%;
    transition-duration: 0.3s;
  }
}

.top-up-tag {
  background-color: var(--gray-100);
  border-radius: 8px;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  margin: 0px 8px 0px 8px;
  color: darkred;
  padding: 2px 6px;
  -webkit-transform: translateY(-2px);
  transform: translateY(-2px);

}



.confirm-warning {
  padding: 4px 8px;
  background-color: #d9822b;
  color: white;
  border-radius: 4px;
  border: 0 solid #d9822b;
  font-weight: bold;
  margin: 0 0 6px 0;
}

.confirm-warning-mobile {
  //padding: 4px 8px;
  background-color: #d9822b;
  color: white;
  border-radius: 4px;
  border: 0 solid #d9822b;
  font-size: 10px;
  font-weight: bold;
  //margin-left: auto;
  //right: 0;
  //margin: 0 0 6px 0;
}

.icon-faded {
  color: transparent;
  background: #02062911;
  border-radius: 100%;
  padding: 2px;
}

.icon-faded-red {
  background: #ef414611;
  color: #ef414611;
  border-radius: 100%;
  //padding: 2px;
  //border: 1px solid #ffcdd2;
}

.icon-red {
  background: #ef4146;
  color: white;
  border-radius: 100%;
  padding: 3px;
  //border: 1px solid #ffcdd2;
}

.icon-warning {
  color: white;
  background: #d9822b;
  border-radius: 100%;
  padding: 3px;
  //padding-bottom: 4px;
  //border: 1px solid #ffcdd2;
}

.icon-selected {
  color: white;
  background: #47b881;
  border-radius: 100%;
  //padding: 2px;
  //border: 2px solid green;
}

.dialogTitleDark {
  background-color: $primary-color;
  color: white;
}

.dialogContent{
  margin: 20px 0 0 0;
}
.stepper {
  max-width: $cont-width;
  padding: 50px;
  margin-left: auto;
  margin-right: auto;
}

.page_header {
  text-align: center;
}


@media (min-width: 768px) {
  .drop-area {
    display: block;
    align-content: center;
    //height: 90%;
    width: 90%;
    max-width: 600px;
    min-width: 300px;
    min-height: 400px;
    //max-height: 400px;
    padding: 20px 20px 20px 30px;
    border: 1px solid $font-color-tab;
    border-radius: 4px;
    color: $font-color-tab;
    flex-grow: 1;
    position: relative;
    text-align: center;
    box-sizing: border-box;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.5;
    margin-right: auto;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    background-color: white;

    &:hover {
      scale: 101%;
      background: #fcfcfc;
      transition-duration: 0.3s;

    }
  }
}

@media (max-width: 768px) {
  .drop-area {
    display: block;
    margin-top: 0;
    //margin-top: auto;
    //margin-bottom: auto;
    align-content: center;
    //height: 70vh;
    max-width: 90%; //600px;
    //min-width: 300px;
    min-height: 300px;
    //max-height: 95%;
    padding: 20px 20px 20px 20px;
    border: 1px solid $font-color-tab;
    border-radius: 4px;
    color: $font-color-tab;
    flex-grow: 1;
    text-align: center;
    box-sizing: border-box;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.5;
    margin-right: auto;
    margin-left: auto;
    background-color: white;

    &:hover {
      scale: 101%;
      background: #fcfcfc;
      transition-duration: 0.3s;

    }
  }

  .main {
    position: fixed;
    height: 100%;
    top: 100px;
    right: 0;
    left: 0;
    bottom: 80px;
    background-color: white;
    display: block;
    width: 100%;
    //height: calc(100vh - 200px);
    //width: calc(100% - 200px);
    h1 {
      color: $primary-color;
      text-align: center;
      padding-top: 6px;
      height: 50px;
    }
  }
  .upload-table-div-mobile {
    //display: block;
    position: absolute;
    width: 100%;
    top: 50px;
    //left: 5%;
    //right: 5%; //margin-right: auto;
    //margin-left: auto;
    bottom: 170px;
    padding-bottom: 0;
    background-color: #02062911;
    overflow-y: scroll;

    .upload-section-mobile {
      display: block;
      border-radius: 4px;
      background-color: white;
      width: 95%;
      margin-right: auto;
      margin-left: auto;
      //margin-top: 10px;
      padding: 10px;
      margin: 5px auto 5px auto;
      box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;

      .upload-section_titles-mobile {
        display: inline-flex;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 2px;
        //padding: 10px;
        //height: 30px;

        .upload-column-name-mobile {
          min-width: 150px;
          max-width: 300px;
          //width: 30%;
          left: 0;
          font-size: 18px;
          font-weight: bold;
          //padding: 5px;

        }
        .upload-column-alert-mobile {
          //width: 80px;
          height: 22px;
         margin: 2px 12px 0 auto;
          font-size: 12px;
          border-radius: 20px;
          //padding-left: 10px;
          //vertical-align: middle;
         //align: right;
        }

          .alert-danger {
            color: #ef4146;
            background-color: transparent;
            text-align: right;
            border-color: transparent;
            padding-right: 0;
          }

        .alert-neutral {
          color: var(--gray-100);
          background-color: transparent;
          text-align: right;
          border-color: transparent;
          padding-right: 0;

        }

        .alert-success {
          color: #47b881;
          background-color: transparent;
          text-align: right;
          border-color: transparent;
          padding-right: 0;

        }

        .upload-flags-mobile {
          //height: 20px;
          display: inline-flex;
          justify-content: right;
          color: $primary-color;
          font-size: 14px;
          p.upload-status-flag-mobile {
            padding: 0;
            margin: 0;
          }
        }
      }

      .upload-section-values-mobile {
        display: inline-flex;
        max-width: 100%;
        margin: 0 5px 0 0;
        padding: 0;
        //height: 50px;


        .upload-selector-mobile {
          //font-size: 10px;
          display: inline-flex;
          width: 150px;

          .upload-drop_down-mobile {
            width: 100%;
            height: 2em;
            //font-size: 14px;
            //margin: 0;
            font-family: 'D-DIN', sans-serif;
          }
        }

        .upload-values_preview-mobile {
          //max-width: 100%;
          overflow: hidden;
          display: inline-flex;
          min-width: 100px;
          font-size: 16px;
          white-space: nowrap;


          upload-values_preview_inner-mobile {
            width: 100%;
            display: inline-flex;
            //display: inline;


          }
        }

        p {
          padding: 4px 10px 0 10px;
          min-width: 200px;
          //height: 20px;
          margin: 0;
        }

        p.ellipsis-p {
          float: left;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }


        p.ellipsis-p:hover {
          overflow: visible;
          white-space: break-spaces;
        }

      }
    }
  }

    .upload-controls {
      display: flex;
      width: 100%;
      left: 0;
      right: 0;
      //font-size: 32px;

      .upload-controls-inner {
        margin-left: auto;
        margin-right: auto;
      }

      .add_row, .remove_row {
        //display: inline-flex;
        //padding: 4px 0 0 0;
      }

      .add_row {
        background-color: transparent;
        border: 0;
        color: #020629cc;
        //margin-left: 5px;
        margin-right: auto;

        &:hover {
          color: $primary-color;
          scale: 150%;
          transition-duration: 0.15s;

        }
      }

      .remove_row {
        margin-left: auto;
        //margin-right: 5px;
        background-color: transparent;
        border: 0;
        color: #ef4146cc;

        &:hover {
          color: #ef4146;
          scale: 150%;
          transition-duration: 0.15s;

        }
      }
    }

}


.amplify-storagemanager__previewer__actions {
  //margin: 50%;
}

.upload-table-desktop {
  height: 100%;
  margin-right: auto;
  margin-left: auto;
  overflow-y: scroll;
  //max-height: calc(100vh - 300px);
  width: 100%;
  background: #02062911;
  border-radius: 0 0 4px 4px;
  border: 1px solid #02062911;
  padding: 1px 10px 1px 10px;
  //background-color: ##ef4146;

}

.upload-section-header-desktop {
  //height: 100px;
  //height: 100%;
  display: inline-flex;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  background: $primary-color;
  color: white;
  border-radius: 4px 4px 0 0;
  border: 1px solid $primary-color;
  padding: 1px 10px 1px 10px;
}

.tablehead1 {
  width: $width_table1;
  display: inline-flex;
  text-align: left;
  font-weight: 600;
  font-size: 18px;
  font-family: 'D-DIN', sans-serif;
  min-width: 85px;

  p {
    margin-left: 0;
    margin-right: auto;
    padding-left: 5px;
  }
}

.tablehead2 {
  width: $width_table2;
  display: inline-flex;
  text-align: left;
  font-weight: 600;
  font-size: 18px;
  font-family: 'D-DIN', sans-serif;
  min-width: 100px;

  p {
    margin-left: 0;
    margin-right: auto;
  }
}

.tablehead3 {
  width: $width_table3;
  display: inline-flex;
  text-align: left;
  font-weight: 600;
  font-size: 18px;
  font-family: 'D-DIN', sans-serif;
  min-width: 100px;

  p {
    margin-left: 0;
    margin-right: auto;
  }
}

.tablehead4 {
  width: $width_table4;
  display: inline-flex;
  text-align: left;
  font-weight: 600;
  font-size: 18px;
  font-family: 'D-DIN', sans-serif;
  min-width: 50px;

  p {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}



.section_titles {
  width: $width_table1;
  font-size: 16px;
  display: inline-flex;
  min-width: 85px;
  vertical-align: middle;
  //background-color: #86c29c;

  p {
    margin-left: 0;
    margin-right: auto;
    padding-left: 5px;
    font-weight: 600;
  }
}

.selector {
  width: $width_table2;
  //font-size: 10px;
  display: inline-grid;
  min-width: 100px;

  .drop_down {
    max-width: 95%;
    height: 2em;
    //font-size: 14px;
    margin: 6px 10px 0 0;
    font-family: 'D-DIN', sans-serif;
  }
}

.values_preview {
  width: $width_table3;
  overflow: hidden;
  display: inline-flex;
  min-width: 100px;
  font-size: 16px;
  white-space: nowrap;


  //.values_preview_inner p {
  //  //width: 100%;
  //  display: inline;
  //  padding-left: 10px;
  //}
}


p.ellipsis-p {
  float: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}


p.ellipsis-p:hover {
  overflow: visible;
  white-space: break-spaces;
}

.flags{
  width: $width_table4;
  font-size: 10px;
  align: center;
  //display: grid;
  min-width: 60px;

  .status-flag{

    //display: inline-flex;
    margin-left: auto;
    margin-right: auto;
    //padding: 4px 0 0 0;
    //background-color: #3b2479;
    margin-bottom: 0;
    padding: 0;
    text-align: center;

    //font-size: 12px;
    .alert-danger{
      background-color: transparent;
      color: #ef4146;
      border: 0;
      text-align: center;
      display: inline-flex;
      padding: 0;
      margin: 0;
      justify-content: center;
      //height: 30px;
    }
    .alert-success{
      background-color: transparent;
      color: #47b881;
      border: 0;
      text-align: center;
      display: inline-flex;
      padding: 0;
      margin: 0;
      justify-content: center;
    }

    .alert-warning{
      background-color: transparent;
      color: var(--gray-100);
      border: 0;
      text-align: center;
      display: inline-flex;
      padding: 0;
      margin: 0;
      justify-content: center;
    }

    .alert-neutral{
      background-color: transparent;
      color: var(--gray-100);
      border: 0;
      text-align: center;
      display: block;
      padding: 0;
      margin: 0;
      justify-content: center;
    }
  }
}

.upload-controls {
  display: flex;
  width: 100%;
  left: 0;
  right: 0;
  //font-size: 32px;

  .upload-controls-inner {
    margin-left: auto;
    margin-right: auto;
  }

  .add_row, .remove_row {
    //display: inline-flex;
    //padding: 4px 0 0 0;
  }

  .add_row {
    background-color: transparent;
    border: 0;
    color: #020629cc;
    //margin-left: 5px;
    margin-right: auto;

    &:hover {
      color: $primary-color;
      scale: 150%;
      transition-duration: 0.15s;

    }
  }

  .remove_row {
    margin-left: auto;
    //margin-right: 5px;
    background-color: transparent;
    border: 0;
    color: #ef4146cc;

    &:hover {
      color: #ef4146;
      scale: 150%;
      transition-duration: 0.15s;

    }
  }
}


.main_part {
  //height: auto;
  flex-grow: 1;
  position: relative;
  box-sizing: border-box;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
  width: 100%;
  //margin: 3% 10%;
}

.selector {
  width: $width_table2;
  height: 40px;
  padding: 5px 0 5px 0;

  &:hover {
    border-color: #0815ff;
  }
}


.badgeLabel {
  padding-top: 6px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 200px;
}

.remove_file {
  display: inline-flex;
  font-size: small;
  background-color: transparent;
  border: 0;
  color: #aaa;

  &:hover {
    color: #ef4146;
    scale: 105%;
    transition-duration: 0.15s;

  }
}


.file-upload-container {
  //margin: 2% 10%;
  margin-right: auto;
  margin-left: auto;
  width: 90%;
  //padding: 20px;
  //background-color: ##ef4146;
}

/* Increase the specificity */


.stepper {

  max-width: 50%;

  .Mui-active {
    color: $primary-color;
  }

  .MuiStepButton-horizontal {
    background: white;
  }

  .MuiSvgIcon-root.MuiStepIcon-root.Mui-completed {
    color: $primary-color;
  }

  .MuiSvgIcon-root {
    color: white;
    border-radius: 100%;
  }

  .MuiStepIcon-text {
    fill: $primary-color;

    :hover {
      fill: #0815ff;
    }
  }


  .MuiStepLabel-iconContainer.Mui-completed {
    margin: 0;
    padding: 0;
    width: 0;
  }

  .MuiStepConnector-line {
    border-color: $primary-color;
    border-top-style: dotted;
    border-top-width: 1px;
  }


  .Mui-completed {
    color: $primary-color;
  }

  .MuiStepLabel-labelContainer {
    width: 0;
    padding: 0;
  }

  .MuiSvgIcon-root.MuiStepIcon-root.Mui-active {
    color: #0815ff;
    fill: $primary-color;
    color: white;
    border: 1px solid $primary-color;
    border-radius: 100%;

    .MuiStepIcon-text {
      fill: white;
    }
  }
}



@media (max-width: 768px) {
  .table-div-upload {
    display: inline;
    position: fixed;
    top: 175px;
    left: 0;
    right: 0; //margin-right: auto;
    //margin-left: auto;
    bottom: 100px;
    background-color: white;

    .section-header {
      display: inline-flex;
      width: 100%;
      min-height: 60px;
      //min-width: 100px;
      //padding-left: 30px;
      margin-right: auto;
      margin-left: auto;
      font-weight: 400;
      font-size: 18px;
      background: $primary-color;
      border-radius: 4px 4px 0 0;
      color: white;
    }
  }
}

@media (min-width: 768px) {
  .table-div-upload {
    display: inline;
    position: fixed;
    left: 200px;
    top: 125px;
    right: 0; //margin-right: auto;
    bottom: 100px;
    background-color: white;

    .section-header {
      display: inline-flex;
      width: 100%;
      min-height: 60px;
      //min-width: 100px;
      //padding-left: 30px;
      margin-right: auto;
      margin-left: auto;
      font-weight: 400;
      font-size: 18px;
      background: $primary-color;
      border-radius: 4px 4px 0 0;
      color: white;
    }




  }
  }


.upload-table-desktop-section {
  display: inline-flex;
  width: 100%;
  margin-top: 5px;
  min-height: 40px;
  margin-right: auto;
  margin-left: auto;
  border-radius: 4px;
  background: #ffffff;

  &:hover {
    background-color: #eee5ff;
    border-radius: 4px;
  }
}

.alert-danger {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 15px;
  p {
    padding: 8px;
    color: white;
  }
}

.alert-warning {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 15px;
  p {
    padding: 8px;
    color: white;
  }
}

.alert-success {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 15px;
  p {
    padding: 8px;
    color: white;
  }
}

.alert-success-a {
  background: #47b881;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 15px;
  p {
    padding: 8px;
    color: white;
  }
}


