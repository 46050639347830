$accent-color: #0815ff;
$accent-color-hover: #6744ff;
$accent-color-disabled: #d1c0ff;

$new-color: #020629;
$new-color-hover: #152a55;
$new-color-disabled: #b5bccb;

$primary-color: #020629;
$primary-color-vox-blue: #020629;

$font-color: #020629;
$font-color-secondary: rgba(0, 7, 51, 0.6);
$font-color-tertiary: rgba(0, 7, 51, 0.5);
$font-color-quaternary: rgba(0, 7, 51, 0.8);
$font-color-disabled: #e2e4e9;
$font-color-tab: #020629;

$font-family: "D-DIN", sans-serif;

$button-tertiary-hover: rgba(255, 255, 255, 0.9);
$button-secondary-hover: rgba(86, 94, 203, 0.1);
$button-secondary-active: rgba(86, 94, 203, 0.15);
$button-secondary-disabled: rgba(86, 94, 203, 0.3);

$button-ordinary-color: #ffffff;
$button-fill-ordinary: #020629;
$button-fill-ordinary-hover: #1a305c;
$button-fill-ordinary-disabled: #e2e4e9;

$button-warning-hover: rgba(255, 0, 0, 0.1);
$button-info-hover: rgba(235, 161, 52, 0.1);
$filled-button-info-hover: rgba(235, 161, 52, 1);

$bg: #ffffff;
$bg-secondary: rgba(7, 13, 88, 0.03);
$bg-content: rgba(7, 13, 88, 0.05);
$bg-modal: rgba(138, 141, 168, 0.8);

$bg-icon: transparent;
$bg-icon-06: rgba(#6a6e9b, .6);
$bg-icon--24: #D7D8E5;
$bg-icon-hover: rgba(7, 13, 88, 0.03);

$bg-icon-chevron: transparent;
$bg-icon-logo: transparent;
$progress-bar-bg: transparent;

$border-color: #020629;
$border-color-light: rgba(7, 13, 88, 0.12);
$border-input-color: rgba(0, 7, 51, 0.27);

$light-bg-stepper: #b5bccb;

$deleted-card-color: rgba(0, 7, 51, 0.05);
$deleted-card-text: #961006;

$dark-danger-color: rgba(183, 28, 28, 1);
$danger-color: rgba(198, 40, 40, 1);
$medium-color: rgba(249, 168, 37, 1);
$positive-color: rgba(46, 125, 50, 1);
$dark-positive-color: rgba(27, 94, 32, 1);

$table-danger-color: #ff7f7f;
$table-medium-color: #f9e076;
$table-positive-color: #86c29c;

$light-background-dense: #f6f6f6;
$light-background-color: rgba(224, 224, 224, 0.3);

$success-color: $accent-color;

$gap: 24px;
$section-gap: 46px;

$shadow: 0 6px 18px -6px rgba(0, 7, 51, 0.1);
$shadow-tooltip: 0 2px 14px rgba(7, 13, 88, 0.12);

$content-padding: 44px;
$survey-card-padding: 24px;

$input-padding-bottom: 23px;
$input-gap: 16px;

$border-radius: 4px;

$checkbox-border-color: #DADADA;

$breakpoint-sm: 640px; // Phone landscape
$breakpoint-md: 960px; // Tablet Landscape
$breakpoint-lg: 1200px; // Desktop
$breakpoint-xl: 1600px; // Large Screens
$breakpoint-xxl: 2000px; // Very Large Screens

$grid-gutters-small: 10px !default;
$grid-gutters: 15px !default;
$grid-gutters-big: 30px !default;

$container-width: 100%;
$total-row-color: #EBEBEB;

:export {
  accentColor: $accent-color;
  accentColorHover: $accent-color-hover;
  accentColorDisabled: $accent-color-disabled;
  newColor: $new-color;
  newColorHover: $new-color-hover;
  newColorDisabled: $new-color-disabled;
  primaryColor: $primary-color;
  fontColor: $font-color;
  fontColorSecondary: $font-color-secondary;
  fontColorTertiary: $font-color-tertiary;
  fontColorDisabled: $font-color-disabled;
  buttonSecondaryHover: $button-secondary-hover;
  buttonSecondaryActive: $button-secondary-active;
  buttonSecondaryDisabled: $button-secondary-disabled;
  bg: $bg;
  bgSecondary: $bg-secondary;
  bgContent: $bg-content;
  bgModal: $bg-modal;
  bgIcon: $bg-icon;
  bgIconHover: $bg-icon-hover;
  bgIconChevron: $bg-icon-chevron;
  bgIconLogo: $bg-icon-logo;
  borderColor: $border-color;
  borderInputColor: $border-input-color;
  dangerColor: $danger-color;
  mediumColor: $medium-color;
  positiveColor: $positive-color;
  darkDangerColor: $dark-danger-color;
  darkPositiveColor: $dark-positive-color;
  gap: $gap;
  sectionGap: $section-gap;
  contentPadding: $content-padding;
  inputPaddingBottom: $input-padding-bottom;
  inputGap: $input-gap;
  borderRadius: $border-radius;
  checkboxBorderColor: $checkbox-border-color;
  progressBadBg: $progress-bar-bg;
  borderColorLight: $border-color-light;
  tableDangerColor: $table-danger-color;
  tableMediumColor: $table-medium-color;
  tablePositiveColor: $table-positive-color;
  lightBackgroundColor: $light-background-color;
  lightBackgroundDense: $light-background-dense;
  totalRowColor: $total-row-color;
}
