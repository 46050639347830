.container {
  width: 100%;
  max-width: $container-width;
  padding-right: $grid-gutters;
  padding-left: $grid-gutters;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
}

.container--no-padding {
  width: 100%;
  max-width: $container-width;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  margin-left: -$grid-gutters;
  margin-right: -$grid-gutters;
  box-sizing: border-box;

  &--strech {
    align-content: stretch;

    [class*='grid-col'] {
      display: flex;
    }
  }

  &--column {
    flex-direction: column;
  }

  &--small {
    margin-left: -$grid-gutters-small;
    margin-right: -$grid-gutters-small;

    [class*='grid-col'] {
      padding-left: $grid-gutters-small;
      padding-right: $grid-gutters-small;
    }
  }

  &--big {
    margin-left: -$grid-gutters-big;
    margin-right: -$grid-gutters-big;

    [class*='grid-col'] {
      padding-left: $grid-gutters-big;
      padding-right: $grid-gutters-big;
    }
  }

  &--between-center {
    justify-content: space-between;
    align-items: center;
  }
}

[class*='grid-col'] {
  width: 100%;
  max-width: 100%;
  padding-left: $grid-gutters;
  padding-right: $grid-gutters;
  box-sizing: border-box;
}

.grid-col-auto { width: auto; }

.grid-col-expand {
  flex: 1;
  min-width: 1px;
}

$from: 1;
$to: 12;

@for $i from $from through $to {
  .grid-col-#{$i} {
    @include add-col($to/$i);
  }
}

@media (min-width: $breakpoint-sm) {
  @for $i from $from through $to {
    .grid-col-sm-#{$i} {
      @include add-col($to/$i);
    }
  }
}

@media (min-width: $breakpoint-md) {
  @for $i from $from through $to {
    .grid-col-md-#{$i} {
      @include add-col($to/$i);
    }
  }
}

@media (min-width: $breakpoint-lg) {
  @for $i from $from through $to {
    .grid-col-lg-#{$i} {
      @include add-col($to/$i);
    }
  }
}

@media (min-width: $breakpoint-xl) {
  @for $i from $from through $to {
    .grid-col-xl-#{$i} {
      @include add-col($to/$i);
    }
  }
}

