@import 'variables';

.top-up {
  max-width:400px;
  min-width: 300px;
  //min-height: 300px;
  max-height: 400px;
  border: 1px solid $font-color-tab;
  border-radius: 4px;
  color: $font-color-tab;
  flex-grow: 1;
  text-align: center;
  box-sizing: border-box;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
  margin-bottom: auto;
  margin-top: 0;
  background-color: white;
  border-color: $button-ordinary-color;
  color: $button-ordinary-color;
  transition: 0.3s;
  border: 1px solid #02062955;
  top: 50px;
  font-size: 14px;
  line-height: 1em;
  margin-left: auto;
  margin-right: auto;

  h1{
    width: 100%;
    top:0;
    //margin: 20px;
    text-align: center;

  }
  h3{
    text-align: center;
  }

  h4{
    text-align: center;
    margin-top: 0;
    padding: 5px;
    background-color: #02062911;
    border-radius: 4px;
  }

  p {
    width: 100%;
    align: center;
    display: inline-flex;


    //button {
    //  margin-left: auto;
    //  margin-right: auto;
    //}
  }
  .top-up-button {
    width:90%;
    margin: auto;
    padding: 14px 18px;
    margin-bottom: 16px;
    border-radius: 4px;
    background: $button-fill-ordinary;
    color: $button-ordinary-color;
    border: 0;
    display:block;
    font-size: 14px;
    line-height: 1em;
    text-transform: uppercase;
    text-align: center;
    align-content: center;
    &:hover {
      scale: 101%;
      transition-duration: 0.3s;
    }
    &:disabled {
      background: #02062955;
    }
  }
}



.form_button {
  width: 60%;
  min-width: 100px;
  padding: 14px 18px;
  border-radius: 4px;
  background: $button-fill-ordinary;
  color: $button-ordinary-color;
  transition: 0.3s;
  border: 0;
  display: block;
  font-size: 14px;
  line-height: 1em;
  text-transform: uppercase;
  margin: 50px auto 10px auto;
}

.icon-payment-success {
  color: white;
  background: #47b881;
  border-radius: 100%;
  font-size: 72px;
  margin: 10px auto 5px auto;
}