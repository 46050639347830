@import "../variables";

@media (min-width:768px) { //and (min-height:1024px)

  .hidden-desktop {
    visibility: hidden;
  }

  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 200px;
    background-color: #ffffff;
    border-right: 1px solid  #02062933;
    display: inline-block;
  }
  .route{
    display: inline-block;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 6px;
    padding-top: 5px;

    border-radius: 4px;
    font-size: 18px;
    height: 40px;
    width: 170px;
    //vertical-align: middle;

    p{
      display: inline;
      margin: auto;
      margin-left: 10px;
    }
  }

  .route-logout{
    position: fixed;
    bottom: 24px;
    margin-left: 14px;
    margin-right: 10px;
    border-radius: 4px;
    font-size: 18px;
    display: inline-block;
    height: 40px;
    width: 170px;
    padding-top: 6px;
    //vertical-align: middle;
    p{
      display: inline;
      margin: auto;
      margin-left: 10px;
    }
  }

  .sidebar a {
    color: $primary-color;
    width: 100%;
    padding: 5px;
  }

  .sidebar button {
    display: inline;
  }

  .sidebar a:hover, a:active {
    color: #0815ff;
    //width: 100%;
  }
  .icon{
    position: relative;
    height: 18px;
    top: 2px;
    vertical-align: text-bottom;
  }
  img {
    height: 40px;
    margin: 10px;
    margin-left: auto;
    margin-right: auto;
    display: inline-flex;

  }
  .route:hover{
    background-color: #eee5ff;
  }

  .route-logout:hover{
    background-color: #eee5ff;
  }
  .logout{
    border: 0 solid ;
    background-color: transparent;
  }
}





@media (max-width:768px) { //and (max-height:1024px)

  .hidden-mobile {
    visibility: hidden;

  }
  /*Mobile css*/
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    //padding-left: 5%;
    //padding-right: 5%;
    //width: 100%;
    //min-width: 375px;
    //position: absolute;
    //top: 0;
    //left: 0;
    background-color: $primary-color;
    //border-bottom: 1px solid #02062911;
  }


  .logo-holder{
    height: 50px;
    display: inline-block;
    margin: 0;
  }
  .voxxify-logo{
    position: relative;
    height: 20px;
    top: 15px;
    left: 15px;
    //right: 10px;
    margin: 0;
    padding: 0;
    //margin-bottom: 14px;
  }


  .top-menu{
    position: fixed;
    display: inline-flex;
    left: 80px;
    right: 0;
    justify-content: space-evenly;
  }
  .route{
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 4px;
    font-size: 16px;
    display: inline-grid;
    height: 40px;
    min-width: 80px;
    //background: $primary-color;
  }

  .route a {
    padding: 8px 0 8px 0;
    color: white;
    text-align: center;
  }
  .sidebar a:hover, a:active {
    //border-bottom: 2px solid #0815ff;
    //width: 100%;
  }

  //img {
  //  height: 40px;
  //  margin: 10px;
  //  display: inline-grid;
  //  vertical-align: text-bottom;
  //}

  .route:hover{
    background-color: #0815ff;
  }
  .logout{
    border: 0 solid ;
    border-radius: 4px;
    color: white;
    background-color: $primary-color;
  }

  .logout:hover{
    background:  #0815ff;
  }

}

@media (min-width:768px) {
.voxxify-logo{
  display: inline;
  position: relative;
  height: 20px;
  top: 15px;
  left: 15px;
  margin: 0;
  padding: 0;
  margin-bottom: 24px;
}
}






