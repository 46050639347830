@import '../variables';
@import '../mixins';

button + button {
	margin-left: $gap;
}

.button {
	padding: 12px 16px;
	border-radius: $border-radius;
	background: $new-color;
	color: #ffffff;
	transition: 0.3s;
	border: 0;
	display: block;
	font-size: 14px;
	line-height: 1em;
	text-transform: uppercase;

	&-lg {
		padding: 14px 20px;
	}

	&-right {
		margin-left: auto;
	}

	&-left {
		margin-right: auto;
	}

	&-center {
		margin-right: auto;
		margin-left: auto;
	}

	&:hover,
	&:focus {
		background: $new-color-hover;
	}

	&:disabled {
		background: $new-color-disabled;
	}

	&-secondary {
		@extend .button;
		color: $new-color;
		border: 1px solid $new-color;
		background: rgb(255,255,255);

		&:hover,
		&:focus {
			background: $button-tertiary-hover;
		}

		&:disabled {
			color: $new-color-disabled;
			background: $button-secondary-hover;
			border: 1px solid $button-secondary-disabled;
		}
	}

	&-warning {
		@extend .button;
		color: $danger-color;
		border: 1px solid $danger-color;
		background: none;

		&:hover,
		&:focus {
			background: $button-warning-hover;
		}
	}

	&-info {
		@extend .button;
		color: $medium-color;
		border: 1px solid $medium-color;
		background: none;

		&:hover,
		&:focus {
			background: $button-info-hover;
		}
	}

	&-ordinary {
		@extend .button;
		color: $button-ordinary-color;
		border: 1px solid $button-ordinary-color;
		background: none;

		&:hover,
		&:focus {
			background: $bg-secondary;
		}
	}

	&-text {
		@extend .button;
		padding: 14px 16px;
		background: none;
		color: $new-color;

		&:hover {
			background: $button-secondary-hover;
		}

		&:active,
		&:focus {
			background: $button-secondary-active;
		}

		&:disabled {
			color: $button-secondary-disabled;
		}
	}

	&-text-gray {
		@extend .button;
		padding: 14px 16px;
		color: $font-color-tab;
		background: none;
		border: none;

		&:hover {
			background: $bg-content;
		}

		&:active,
		&:focus {
			background: $bg-content;
		}

		&:disabled {
			color: $bg-content;
		}
	}

	&-simple {
		position: relative;

		&::before {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			right: 100%;
			border-bottom: 1px solid $new-color;
			transition: right 0.3s ease-out;
		}

		&:focus {
			background: transparent;
		}

		&:hover {
			background: transparent;

			&::before {
				right: 0;
			}
		}
	}

	&-icon {
		@extend .button;
		display: flex;
		align-items: center;
	}

	&__icon {
		margin-right: 8px;

		svg {
			width: 10px;
			height: 10px;
		}
	}

	&-load-more {
		position: absolute;
		right: 0;
		bottom: 0;
		transform: translateY(100%);
	}
}

.round-button {
	display: flex;
	align-items: center;

	&__icon {
		@include size(20px);
		color: #ffffff;
		border-radius: 50%;
		background: $new-color;
		padding: 5px;
		cursor: pointer;
		fill: #ffffff;

		&.lg {
			@include size(44px);
			padding: 15px;
		}
	}

	&__text {
		margin-left: 20px;
	}
}
