@mixin size($width, $height: $width) {
  width:  $width;
  height: $height;
}

@mixin font-size($font-size, $line-height: null, $units: px) {
  font-size: $font-size + $units;
  line-height: $line-height + $units;
}

@mixin useBreakpoint($bp) {
  @media screen and (max-width: $bp) {
    @content
  }
}

@mixin useMinBreakpoint($bp) {
  @media screen and (min-width: $bp) {
    @content
  }
}

@mixin add-col($columns) {
  width: percentage(1 / $columns);
}
