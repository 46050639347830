@import '../variables';
@import '../variables';

.form {
  &-section {
    background:    #fff;
    border-radius: 4px;

    &__title {
      font-size:   18px;
      line-height: 1.33;

      & + .row {
        margin-top: 0;
      }
    }

    &__block {
      padding: 40px 40px 0;

      & > .row:first-of-type {
        margin-top: 0;
      }
    }
  }

  &-block {
    & + & {
      margin-top: $content-padding;
    }

    & > &__title {
      margin-top: 26px;
    }

    &__title-with-tooltip {
      display: flex;
    }
  }
}

.company {
  &-form {
    margin-top:     28px;
    padding-bottom: 40px;

    &__btns {
      border-top: 1px solid $border-color;
    }

    .col-2 .group--datepicker {
      &:first-of-type {
        padding-bottom: 12px;
      }

      &:last-of-type {
        margin-top: 12px;
      }
    }

    .radio {
      margin-top: 30px;
    }
  }
}
